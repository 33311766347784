/* Custom CSS class for the frontend */

.react-calendar {
    width: 80%;
    max-width: 100%;
    background: white;
    border: 3px solid #a0a096;
    border-radius: 10px;
    font-family: Arial, Helvetica, sans-serif;
    line-height: 1.125em;
}

.react-calendar__tile--active{
    background: #5633a3 !important;
    color: white !important;
}

.react-calendar__tile--now{
    background:#f200495b !important;
    color: white !important;
}

.text-appcentric {
    background-image: linear-gradient(94deg, #5633a3 0%, #f2004a 100%);
    color: transparent;
    -webkit-background-clip: text;
    background-clip: text;
}

.btn-appcentric {
    background-image: linear-gradient(94deg, #5633a3 0%, #f2004a 100%);
    color: #fff !important; 
}

.bg-appcentric {
    background-image: linear-gradient(94deg, #5633a3 0%, #f2004a 100%);
}

/* Override falcon CSS class for the frontend */

.nav-link.active{
    color: #5633a3 !important;
}

.bg-soft-success{
    background-attachment: #d9f8eb !important;
}